<template>
  <div class="product-box p-2 d-flex ml-2 align-items-center">
    <b-img
      :src="
        product &&
        product._source &&
        product._source.product_summary &&
        product._source.product_summary.images
          ? product._source.product_summary.images.icon
          : ''
      "
      fluid
      alt
    />
    <div class="ml-4">
      <h5 class="text-center">
        {{
          product && product._source ? product._source.description_summary : ""
        }}
      </h5>
      <!-- <p class="text-center">Health Test Service Provider</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "healthian-product",
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.product-box {
  border: 1px solid #b7e4e7;
  border-radius: 10px;
  max-width: 30%;
  min-width: 30%;
  max-height: 80px;
  background-color: #fff;
}
.product-box p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #00a0a8;
}
.product-box h5 {
  /* margin-bottom: 0px; */
  color: #00a0a8;
  font-size: 21px;
  font-weight: 500;
}
</style>
