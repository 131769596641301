var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-box p-2 d-flex ml-2 align-items-center"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.product && _vm.product._source && _vm.product._source.product_summary && _vm.product._source.product_summary.images ? _vm.product._source.product_summary.images.icon : '',
      "fluid": "",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "ml-4"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.product && _vm.product._source ? _vm.product._source.description_summary : "") + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }